body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Albert-Italic';
    src: url('assets/fonts/AlbertSans-Italic.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'Albert-Medium';
    src: url('assets/fonts/AlbertSans-Medium.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'Albert-Bold';
    src: url('assets/fonts/AlbertSans-Bold.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'Albert-Regular';
    src: url('assets/fonts/AlbertSans-Regular.ttf') format('truetype');
    font-style: normal;
}
